import '@ibm/plex/css/ibm-plex.min.css'
import 'animate.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import '../assets/scss/init.scss'

import React, { useRef } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import { Layout } from '../components/layout/Layout'

const AboutPage: React.FC = () => {
    const contentRef = useRef(null)
    const executeScroll = () => {
        if (contentRef && contentRef.current) {
            const node = contentRef.current as HTMLElement
            node.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            })
        }
    }

    return (
        <Layout>
            <main>
                <div className="page">
                    <div className="landing idx">
                        <div className="text animate__animated animate__fadeInUp">
                            <div className="title">About us</div>
                            <div className="desc">ArgatSoft is a custom software design and development</div>
                            <div className="desc">company based in Calgary, Alberta, Canada</div>
                            <div className="link">
                                <Link to="/contact">CONNECT WITH US</Link>
                            </div>
                        </div>
                        <a className="explore" onClick={executeScroll}>
                            <div className="explore-text">Explore</div>
                            <div className="explore-arrow bounce-animation"></div>
                        </a>
                        <div className="image-wrap">
                            <StaticImage
                                className="image"
                                src="../images/landing/about.jpg"
                                alt=""
                                layout="constrained"
                                objectFit="fill"
                                objectPosition="center"
                                quality={100}
                            />
                        </div>
                    </div>
                    <div className="content" ref={contentRef}>
                        <section className="light">
                            <h4 className="text-navy">
                                <span>About </span>ArgatSoft<span> Solutions</span>
                            </h4>
                            <div className="centered">
                                <p>
                                    We are a dedicated team of software engineers developing custom software
                                    applications and mobile apps that are 100% tailored to your needs. All we build is
                                    for you and in collaboration with you, so you can think of us as your team&apos;s
                                    expansion.
                                </p>
                                <p></p>
                            </div>
                        </section>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default AboutPage
